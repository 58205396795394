<template>
  <el-tabs type="border-card" v-if="t_menu&&t_menu.length">
    <el-tab-pane v-for="(subTab,tabIndex) in t_menu" :label="subTab.name" :key="tabIndex">
        <div class="sub-tab-pane">
          <div class="sub-menu-block" v-for="(subMenu,subIndex) in subTab.child" :key="subIndex">
            <el-checkbox
                v-model="subMenu.canHandle"
                :indeterminate="getSelectedLen(subMenu)>0 && getSelectedLen(subMenu)<subMenu.child.length"
                class="fontBold"
                :disabled="!editable"
                v-if="canCutMenu"
                @change="handleCheckAllSubMenu(subMenu)">
            </el-checkbox>
            <span v-if="!subMenu.edit"
                  class="lineHeight32"
                  @click.prevent="labelClick(subMenu)">
                {{subMenu.name}}
              </span>
            <el-input v-model="subMenu.name"
                      v-if="subMenu.edit"
                      maxlength="20"
                      :ref="'front_menu_'+subMenu.id"
                      @blur.native.capture="saveNode(subMenu)"></el-input>
            <div class="sub-menu-item-block">
              <div class="sub-menu-item" v-for="(subMenuItem,subMenuIndex) in subMenu.child" :key="subMenuIndex" >
                <el-checkbox :disabled="!editable"
                             v-if="canCutMenu"
                             v-model="subMenuItem.canHandle"
                             @change="subMenuItemChange(subMenu)">
                </el-checkbox>
                <span v-if="!subMenuItem.edit"
                      class="lineHeight32"
                      @click.prevent="labelClick(subMenuItem)">
                    {{subMenuItem.name}}
                  </span>
                <el-input v-model="subMenuItem.name"
                          v-if="subMenuItem.edit"
                          :ref="'front_menu_'+subMenuItem.id"
                          @blur.native.capture="saveNode(subMenuItem)"></el-input>
                <div class="icon-block" :class="[!editable?'disabled':'']">
                  <span>图标</span>
                  <div :style="{backgroundColor: subMenuItem.iconColor}"
                       :class="['selected_icon']"
                       v-if="!subMenuItem.iconEdit"
                       @click="$set(subMenuItem,'iconEdit',true)">
                    <img :src="subMenuItem.iconUrl" />
                  </div>
                  <edit-icon :show.sync="subMenuItem.iconEdit"
                             :immediate="true"
                             :icon.sync="subMenuItem.iconUrl"
                             :color.sync="subMenuItem.iconColor"></edit-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import EditIcon from "./edit-icon";
export default {
  name: "front-menu",
  components: {EditIcon},
  props:{
    menu:Array,
    editable:{
      type:Boolean,
      default:false
    },
    canCutMenu:{
      type:Boolean,
      default:false
    }
  },
  computed:{
    't_menu':{
      get(){
        return this.menu;
      },
      set(val){
        this.$emit('update:menu',val)
      }
    }
  },
  methods:{
    saveNode(data){
      const name = data.name.substr(0,20)
      this.$set(data,'edit',false)
      this.$set(data,'name',name||'默认标题')
    },
    handleCheckAllSubMenu(data){
      data.child = data.child.map(e=>{e.canHandle = data.canHandle;return e})
    },
    getSelectedLen(menu){
      const result = menu.child && menu.child.filter(e=>e.canHandle)
      return  result?result.length:0
    },
    subMenuItemChange(subMenu){
      subMenu.canHandle = this.getSelectedLen(subMenu)>1;
    },
    labelClick(subMenu){
      if(this.editable){
        this.$set(subMenu,'edit',true)
        this.$nextTick(()=>{
          this.$refs['front_menu_'+subMenu.id][0].focus();
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.sub-menu-block{
  .sub-menu-item-block{
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .sub-menu-item{
      margin: 0 20px 10px 20px;
      min-width:135px;
      .el-input{
        width: 110px;
      }
      .icon-block{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100px;
        .selected_icon {
          width: 50px;
          height: 50px;
          border-radius: 8px;
          cursor: pointer;
          overflow: hidden;
          box-sizing: border-box;
          padding: 12px;

          img {
            width: 100%;
            height: 100%;
          }
          &.opacity0{
            opacity: 0;
            width: 0;
            height: 0;
            display: none;
          }
        }
        &.disabled{
          pointer-events: none;
          cursor: pointer;
          opacity: 0.5;
        }
      }
    }
  }
}
::v-deep .el-checkbox__label{
  font-size: 14px;
}


</style>